<template>
  <div>
    <van-notice-bar left-icon="volume-o" :color="color" :background="background" :class="{ isFour: isFour }"
      v-if="CommonNotice.length > 0">
      <span v-for="nItem in CommonNotice" :key="nItem.Id" class="notice" @click="handleClick(nItem)">{{ nItem.Content
        }}</span>
    </van-notice-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getLangName } from '@/utils/validate'
import { EventBus } from '@/utils/event-bus'
export default {
  props: {
    color: {
      type: String,
      default: '#0957ac',
    },
    background: {
      type: String,
      default: '#ffffff',
    },
    isFour: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CommonNotice: [],
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  mounted() {
    EventBus.$on('changeLanguage', () => {
      this.getNotice()
    })
    this.getNotice()
  },
  methods: {
    handleClick(notice) {
      this.$dialog.alert({
        title: notice.Title,
        message: notice.Content,
      })
    },
    getNotice() {
      let LanguageType = getLangName(localStorage.getItem('language'))
      this.CommonNotice = this.basicConfig.CommonNotice.filter(item => item.LanguageType == LanguageType);
    }
  },
}
</script>

<style lang="stylus" scoped>
.van-notice-bar
  height 35px
  line-height 35px !important
  >>> .van-notice-bar__wrap
    line-height 30px
  .notice
    margin-right 60px
.isFour
  margin 10px 0
</style>
